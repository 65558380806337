import React from 'react'
import renderBlok from '@renderBlok'
import SbEditable from 'storyblok-react'
import { makeStyles } from '@material-ui/styles'
import { Box } from '@material-ui/core'
import { H4, HR } from '@system'
import PageContainer from '@system/page-container'
import ContentContainer from '@system/content-container'
import getFormattedDateTime from '@helpers/get-formatted-date-time'
import { Link as GatsbyLink } from 'gatsby'

const useStyles = makeStyles((theme) => ({
  calendarModuleRoot: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  noEventsText: {
    width: '60%',
    padding: '3rem 0',
    textAlign: 'center',
    margin: 'auto',
  },
  linkText: {
    color: theme.palette.primary.main,
  },
}))

const CalendarModule = (props) => {
  const classes = useStyles()
  const { events } = props.blok
  const futureEvents = events.filter(
    (event) =>
      getFormattedDateTime(event.endDate || event.startDate) >=
      getFormattedDateTime(new Date().setHours(0, 0, 0, 0))
  )

  const noEventsPlaceholder = (
    <H4 className={classes.noEventsText}>
      We currently have no upcoming events to display, but check out our&nbsp;
      <GatsbyLink to="/webinars" className={classes.linkText}>
        live and on-demand webinars
      </GatsbyLink>
      .
    </H4>
  )

  return (
    <SbEditable content={props.blok}>
      <PageContainer>
        <ContentContainer>
          <Box className={classes.calendarModuleRoot}>
            <HR
              color={{ color: '#000' }}
              widthProportion={100}
              lineHeight={1}
            />
            {!futureEvents.length && noEventsPlaceholder}
            {futureEvents.map((event, i) =>
              renderBlok(event, { shouldRenderHR: i + 1 < futureEvents.length })
            )}
            <HR
              color={{ color: '#000' }}
              widthProportion={100}
              lineHeight={1}
            />
          </Box>
        </ContentContainer>
      </PageContainer>
    </SbEditable>
  )
}

export default CalendarModule
